import React from 'react';
import { withStyles } from '@material-ui/core/styles'

const style = {
  root: {
    overflow: 'hidden',
    textAlign: 'center',
  },
}
export default withStyles(style)(
  ({ classes, ...props }) => <div className={classes.root} {...props} />,
)
