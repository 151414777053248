import React from "react";
import qs from "qs";
import { Redirect, Router, Route } from "react-router";
import context from "./context";
import Root from "../student/components/Root";

// const getClassroomStatus = async (courseID) => {
//   const { search } = window.location;
//   const fixedQS = search.replace(/[+]/g, '%2B');
//   const { courseID } = qs.parse(fixedQS.slice(1));
//   const { CLASSROOM_SERVER } = process.env;
//   const response = await fetch(`${CLASSROOM_SERVER}/${courseID}/status`, {
//     method: 'GET',
//     headers: { Authorization: 'test' },
//   });

//   if (!response.ok) {
//     const message = `An error has occured: ${response.status}`;
//     throw new Error(message);
//   }

//   const status = await response.text();
//   console.log(status);
//   return status;
// };

class Classroom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  async componentDidMount() {
    const { search } = window.location;
    const fixedQS = search.replace(/[+]/g, "%2B");
    const { courseID } = qs.parse(fixedQS.slice(1));
    const { CLASSROOM_SERVER } = process.env;
    const response = await fetch(`${CLASSROOM_SERVER}/${courseID}/status`, {
      method: "GET",
      headers: { Authorization: "ER!R3PWx6s4&j&" },
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const status = await response.json();
    this.setState({ isActive: status });
    console.log(status);
  }

  render() {
    const { isActive } = this.state;
    if (isActive.status === true) {
      context.activationState = true;
      return (
        <Redirect
          exact
          to={{
            pathname: "/student/",
            search: window.location.search,
          }}
        />
      );
    }
    return (
      <Root>
        <h1>Please wait for your trainer to activate this class</h1>
      </Root>
    );
  }
}
export default Classroom;
