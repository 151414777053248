import React, { Suspense } from "react";
import { Switch, Route } from "react-router";
import Student from "./student";
import Trainer from "./trainer";
import Perf from "./perf";
import NoMatch from "./NoMatch";
import Classroom from "./classroom";

export default () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <Route path="/vc/" component={Classroom} />
      <Route path="/student/" component={Student} />
      <Route path="/perf/rdp/" component={Perf} />
      <Route path="/login" component={Trainer} />
      <Route path="/course" component={Trainer} />
      <Route path="/session" component={Trainer} />
      <Route exact path="/" component={Trainer} />
      <Route component={NoMatch} />
    </Switch>
  </Suspense>
);
